'use es6';

// Follows the Meetings embed code example
// https://git.hubteam.com/HubSpot/MeetingsUI/blob/master/MeetingsEmbed/static/js/MeetingsEmbedCode.js

/********** Start of HS UTK UUID generation **********/
// Copied from conversations-visitor/conversations-embed/static/js/utils/hsGenerator.js
function mathRandomUuid() {
  let d = new Date().getTime();
  return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, c => {
    // eslint-disable-next-line no-bitwise
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : r & 0x3 | 0x8).toString(16);
  });
}
function cryptoUuid() {
  const cryptoLib = window.crypto || window.msCrypto;
  const buf = new Uint16Array(8);
  cryptoLib.getRandomValues(buf);
  const S4 = function S4(num) {
    let ret = num.toString(16);
    while (ret.length < 4) {
      ret = `0${ret}`;
    }
    return ret;
  };
  return S4(buf[0]) + S4(buf[1]) + S4(buf[2]) + S4(buf[3]) + S4(buf[4]) + S4(buf[5]) + S4(buf[6]) + S4(buf[7]);
}
function generateUtk() {
  const cryptoLib = window.crypto || window.msCrypto;
  if (typeof cryptoLib !== 'undefined' && typeof cryptoLib.getRandomValues !== 'undefined' && typeof window.Uint16Array !== 'undefined') {
    return cryptoUuid();
  }
  return mathRandomUuid();
}
/********** End of HS UTK UUID generation **********/

function elementContainsIFrame(element) {
  return element.querySelectorAll('iframe').length > 0;
}
function getParentPageUrlQueryParam() {
  return `&parentPageUrl=${window.location.origin}${window.location.pathname}`;
}
function getParentPageUtkQueryParam() {
  const parentPageUtk = getOrSetHubSpotUtk();
  if (parentPageUtk) {
    return `&parentHubspotUtk=${parentPageUtk}`;
  }
  return '';
}
function getParentPageHsVarsQueryParam() {
  if (window.hsVars) {
    return `&hsVars=${encodeURIComponent(JSON.stringify(window.hsVars))}`;
  }
  return '';
}
function getLayoutQueryParam(src) {
  if (!src.includes('layout')) {
    return '&layout=embed';
  }
  return '';
}
function getCookieValueByName(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      const cookieName = `${name}=`;
      if (cookie.substring(0, name.length + 1) === cookieName) {
        cookieValue = cookie.substring(name.length + 1);
        break;
      }
    }
  }
  return cookieValue;
}
function getOrSetHubSpotUtk() {
  let utk = window.__hsUserToken || getCookieValueByName('hubspotutk');
  if (!utk) {
    const newUtk = generateUtk();
    utk = newUtk;
    window.__hsUserToken = newUtk;
  }
  return utk;
}
function isHubSpotOrigin(origin) {
  const regex = /hubspot(?:qa)?\.com/;
  return regex.test(origin);
}
function startScript() {
  const containers = document.querySelectorAll('.payments-iframe-container');
  let i;
  let resize;
  for (i = 0; i < containers.length; i++) {
    const container = containers[i];
    const src = container.dataset.src;
    const iframe = document.createElement('iframe');

    // iframe attributes
    container.height = '100%';
    iframe.src = encodeURI(src) + getLayoutQueryParam(src) + getParentPageUrlQueryParam() + getParentPageHsVarsQueryParam() + getParentPageUtkQueryParam();
    iframe.width = '100%';
    iframe.style.minWidth = '320px';
    iframe.style.minHeight = '500px';
    iframe.style.height = '1332px';
    iframe.style.border = 'none';
    iframe.allow = 'payment *';
    if (elementContainsIFrame(container)) {
      // iframe already appended from a previous script
      continue;
    }
    container.appendChild(iframe);
    // Listen for message from iframe
    resize = function (e) {
      const key = e.message ? 'message' : 'data';
      const data = e[key];
      if (isHubSpotOrigin(e.origin) && data.height) {
        iframe.style.height = `${data.height}px`;
      }
    };
    window.addEventListener('message', resize);
  }
}
document.addEventListener('DOMContentLoaded', () => {
  startScript();
});